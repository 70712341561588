import { Box, Button, Grid, Typography } from '@mui/material';
import { AuthenticatedLayout } from 'common/components/AuthenticatedLayout';
import { Image } from 'common/components/Image';
import { Link } from 'common/components/Link';
import React from 'react';

const Error404 = () => {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'} spacing={4}>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'center'}>
          <Image
            alt={'Not found'}
            layout={'fixed'}
            width={400}
            height={400}
            src={'gs://maastr_production/404.gif'}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h1'} align={'center'}>
          {"Seems like you're lost"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'center'}>
          <Button color={'primary'} variant={'contained'}>
            <Link color={'textPrimary'} href={'/'}>
              Take me home!
            </Link>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

Error404.layout = (props: any) => (
  <AuthenticatedLayout requireAuth={false} {...props} />
);

export default Error404;
